import React from 'react';

import { IFormHandleExtended } from '../../../../components/FormQuiz';
import { ParticipantStep } from '../../../../../../../types/v3Types';

export interface ISidebarLayoutBaseContext {
  formQuizRef: React.RefObject<IFormHandleExtended>; // we need it to have a way to submit a form from btn located in a sibling component
  currentStep: ParticipantStep;
  isCurrentStepHidden: boolean;
  goToCurrentStep(): void;
  pageRef: React.RefObject<HTMLElement>;
  videoStepAccepted: boolean;
  setVideoStepAccepted(videoStepAccepted: boolean): void;
  isVideoLoadingError: boolean;
  setIsVideoLoadingError(isError: boolean): void;
  resolveCurrentStep(): Promise<void>;
  isAutoPlayVideo: boolean;
  changeAutoplayOptions(isAutoplayEnabled: boolean): Promise<void>;
  checkAutoNavigate(): Promise<void>;
  isSectionsWithoutDescriptionEnabled: boolean;
  changeSectionsWithoutDescriptionEnabled(isEnabled: boolean): void;
}

export const SidebarLayoutBaseContext =
  React.createContext<ISidebarLayoutBaseContext>({
    formQuizRef: null,
    currentStep: null,
    isCurrentStepHidden: false,
    goToCurrentStep: () => {},
    pageRef: null,
    videoStepAccepted: false,
    setVideoStepAccepted: () => {},
    isVideoLoadingError: false,
    setIsVideoLoadingError: () => {},
    resolveCurrentStep: async () => {},
    isAutoPlayVideo: false,
    changeAutoplayOptions: async () => {},
    checkAutoNavigate: async () => {},
    isSectionsWithoutDescriptionEnabled: false,
    changeSectionsWithoutDescriptionEnabled: () => {},
  });

export function useSidebarLayoutBase(): ISidebarLayoutBaseContext {
  return React.useContext(SidebarLayoutBaseContext);
}
